import 'classlist-polyfill';
import CookieNotification from '@esign/cookie-notification';

import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
import RecaptchaForm from './components/forms/RecaptchaForm';
import ModalManager from './components/modals/ModalManager';

import Contact from './pages/Contact';
import Components from './pages/Components';

require('./utils/nativeConsole');
// require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc
// import objectFitImages from 'object-fit-images';
// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Cookie notification
console.log('cookie notif');
window.cookieNotification = new CookieNotification({
  cookies: {
    marketing: true,
  },
});

// Layout setup
responsiveVideos();
// eslint-disable-next-line
window.modalManager = new ModalManager();

// Forms
validation();

if (document.getElementById('form-contact')) {
  const contactForm = new RecaptchaForm('#form-contact');
  window.submitRecaptchaFormContact = () => {
    contactForm.submitCallback();
  };
}

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
  Components,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName](); // eslint-disable-line no-new
  }
}

window.modalManager.checkModalOnPageLoad();
